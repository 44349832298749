import { Box, Flex, Space } from '@mantine/core'
import { useState } from 'react'
import { ProfileImageWithVideo } from './ProfileImageWithVideo'
import { ReviewCardSummary } from '~/components/reviews/ReviewSummary'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { Typography } from '~/components/shared/text/Typography'
import { ProfileSummaryType, ServiceType } from '~/endpoints/model'
import { useScreenSize } from '~/hooks'

import SellerBadge from '../SellerBadge'
import { IntroVideoModal } from './IntroVideoModal'
import { ThatchButton } from '~/components/shared/ThatchButton'

export const maxWidth = 824

const ServicePriceTag = ({
    type,
    price,
    username,
}: {
    username: string
    type: ServiceType
    price: number
}) => {
    const title =
        type === ServiceType.consultation
            ? 'Consultation Call'
            : ServiceType.itinerary === type
                ? 'Custom Itinerary'
                : 'Custom Guide'

    return (
        <ThatchLink
            to={`/seller/services/${type}/${username}`}
            isOpenInNewWindow
        >
            <Flex
                bg="appPaper.0"
                align="center"
                px={12}
                py={8}
                sx={{ borderRadius: 20 }}
            >
                <Typography
                    variant="tag"
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    ${price} {title}
                </Typography>
            </Flex>
        </ThatchLink>
    )
}
type ProfileCardProps = {
    data: ProfileSummaryType
    sellerSearchView?: boolean
    withBorders?: boolean
}
const ProfileCard = ({
    data,
    sellerSearchView,
    withBorders = false
}: ProfileCardProps) => {
    const { isMobileScreen } = useScreenSize()
    const [showIntroVideoModal, setShowIntroVideoModal] = useState(false)
    const [playVideo, setPlayVideo] = useState(false)
    const {
        username,
        sellerPhoto,
        photo,
        sellerIntroVideo,
        name,
        bio,
        home,
        travelTypes,
        recs,
        itinerary,
        consultation,
        proStatus,
        rating,
        reviews,
    } = data
    return (
        <>
            <Flex
                direction="column"
                gap={isMobileScreen ? 13 : 8}
                justify="end"
                onMouseEnter={() => setPlayVideo(true)}
                onMouseLeave={() => setPlayVideo(false)}
                className={`hover:bg-[#F8F8F8] ${withBorders ? 'p-4 border border-black/10 rounded-lg bg-white' : 'py-8'} group`}
            >
                <Flex
                    p={sellerSearchView ? 0 : 24}
                    maw={maxWidth}
                    sx={sellerSearchView ? {} : { border: '1px solid rgba(0, 0, 0, 0.20)' }}
                    gap={isMobileScreen ? 10 : 24}
                >
                    <Box sx={{ flexShrink: 0, borderRadius: 8, overflow: 'clip' }} w={isMobileScreen ? 113 : 168} h={isMobileScreen ? 149 : 224}>
                        <ProfileImageWithVideo profile={data} w={isMobileScreen ? 113 : 168} h={isMobileScreen ? 149 : 224} play={playVideo || isMobileScreen} />
                    </Box>
                    <Flex
                        w="100%"
                        direction="column"
                        gap={12}
                        justify={'space-between'}
                    >
                        <ThatchLink
                            to={`/@${username}`}
                            isOpenInNewWindow
                            underline={false}
                        >
                            <Flex
                                justify={isMobileScreen ? 'start' : 'space-between'}
                                align="center"
                                gap={8}
                            >
                                <Typography
                                    variant={isMobileScreen ? 'title2' : 'title'}
                                    lineClamp={1}
                                >
                                    {name}
                                </Typography>

                                <SellerBadge
                                    type={proStatus}
                                    hideText={isMobileScreen}
                                />
                            </Flex>

                            <Space h={12} />

                            <Flex
                                direction="column"
                                gap={12}
                                h="100%"
                                sx={{ marginTop: -3.5, flex: 1 }}
                            >
                                {home && (
                                    <Flex
                                        align="center"
                                        gap={4}
                                    >
                                        <SvgIcon type={SvgIconType.HOME_ICON_OUTLINE} />
                                        <Typography variant="tag">Lives in {home.name}</Typography>
                                    </Flex>
                                )}

                                <Typography
                                    mt={!home ? 4 : 0}
                                    variant={isMobileScreen ? 'caption' : 'body3'}
                                    color="appPlaceholder.0"
                                    lineClamp={4}
                                    style={{ textOverflow: 'ellipsis' }}
                                >
                                    {bio}
                                </Typography>

                                {travelTypes && (
                                    <Typography
                                        variant="tag"
                                        lineClamp={1}
                                        style={{ textOverflow: 'ellipsis', lineHeight: '150%' }}
                                    >
                                        {travelTypes.join('  •  ')}
                                    </Typography>
                                )}
                            </Flex>
                        </ThatchLink>

                        {!isMobileScreen && sellerSearchView && (
                            <Flex
                                align="center"
                                gap={8}
                                justify="space-between"
                            >
                                <ReviewCardSummary
                                    rating={rating}
                                    reviews={reviews}
                                />
                                <div />
                                <ThatchLink to={`/@${username}`} isOpenInNewWindow>
                                    <ThatchButton
                                        size="extraSmall"
                                        bg="appSuccess.0"
                                        className='group overflow-visible'
                                    >

                                        <Typography variant="button_small" mr={8}>View Profile</Typography>
                                        <div className="opacity-0">
                                            <SvgIcon type={SvgIconType.ARROW_RIGHT} fill='appWhite.0' />
                                        </div>
                                        <div className="group-hover:translate-x-1 absolute right-4 transition-transform">
                                            <SvgIcon type={SvgIconType.ARROW_RIGHT} fill='appWhite.0' />
                                        </div>
                                    </ThatchButton>
                                </ThatchLink>
                                {/* <ServicePriceTags
                                    username={username}
                                    consultation={consultation}
                                    itinerary={itinerary}
                                    recs={recs}
                                /> */}
                            </Flex>
                        )}
                    </Flex>
                </Flex>


                {isMobileScreen && sellerSearchView && (
                    <Flex
                        justify="space-between"
                        maw="90vw"
                        align="center"
                        gap={8}
                        sx={{ cursor: 'pointer' }}
                    >
                        <ReviewCardSummary
                            rating={rating}
                            reviews={reviews}
                        />
                        <div />
                        <ThatchLink to={`/@${username}`} isOpenInNewWindow>
                            <ThatchButton
                                size="extraSmall"
                                bg="appSuccess.0"
                            >

                                <Typography variant="button_small" mr={8}>View Profile</Typography>
                                <SvgIcon type={SvgIconType.ARROW_RIGHT} fill='appWhite.0' />
                            </ThatchButton>
                        </ThatchLink>
                    </Flex>
                )}

                {!sellerSearchView && (
                    <Typography
                        variant="body3"
                        sx={{ fontStyle: 'italic !important' }}
                        color="appPlaceholder.0"
                    >
                        Preview of your listing in Thatch search results
                    </Typography>
                )}
            </Flex >

            {showIntroVideoModal && sellerIntroVideo && (
                <IntroVideoModal
                    opened={showIntroVideoModal}
                    setOpened={setShowIntroVideoModal}
                    videoUrl={sellerIntroVideo}
                    profile={data}
                />
            )}
        </>
    )
}

export default ProfileCard
