import { Box, Image, Flex } from '@mantine/core'
import { IconPlayerPlay } from '@tabler/icons'
import { useState, useRef, useEffect } from 'react'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { getImageUrl } from '~/components/shared/image/helpers'
import { ProfileSummaryType } from '~/endpoints/model'
import { IntroVideoModal } from './IntroVideoModal'
import { getVideoUrl } from '~/components/shared/video'
import { Typography } from '~/components/shared/text/Typography'

export function ProfileImageWithVideo({ profile, w = 222, h = 296, play = true, alwaysShowButton = false }: { profile: ProfileSummaryType, w?: number, h?: number, play?: boolean, alwaysShowButton?: boolean }) {
    const [showIntroVideoModal, setShowIntroVideoModal] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)

    const {
        sellerPhoto,
        photo,
        sellerIntroVideo,
    } = profile

    useEffect(() => {
        if (play && videoRef.current) {
            videoRef.current.play()
        } else if (videoRef.current) {
            videoRef.current.pause()
        }
    }, [play])

    return (<>
        <Box sx={{ position: 'relative', width: w, height: h }} className='group'>
            {sellerIntroVideo && <video muted loop playsInline className={`object-cover transition-opacity`} style={{ width: w, height: h, zIndex: 10, position:'absolute', top: 0, left: 0, opacity: play ? 1 : 0.01 }} ref={videoRef}>
                <source type="video/mp4" src={getVideoUrl(sellerIntroVideo)} />
            </video>}
            <Image
                src={getImageUrl({
                    src: sellerPhoto || photo,
                    width: w
                })}
                width={w}
                height={h}
                fit="cover"
                alt="profile-img"
                classNames={{
                    root: 'absolute top-0 left-0 z-9',
                }}
                className=''
            />
            {sellerIntroVideo && (
                <Flex sx={{ zIndex: 11 }} justify="center" className={`absolute w-full h-full md:h-auto md:bottom-3 items-center ${alwaysShowButton ? '' : 'md:opacity-0 group-hover:opacity-100'}`}>
                    <ThatchButton
                        className='w-10 h-10 rounded-full md:w-auto md:h-unset md:rounded-unset'
                        style={{
                            background: 'rgba(255, 255, 255, 0.7)',
                        }}
                        label={
                            <Typography variant='button_xsmall' className='hidden md:block'>Watch Intro</Typography>
                        }
                        onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setShowIntroVideoModal(true)
                        }}
                        size="extraSmall"
                        variant="white"
                        leftIcon={
                            <IconPlayerPlay
                                fill="black"
                                height={18}
                                width={18}
                                className='ml-4 md:ml-0'
                            />
                        }
                    />
                </Flex>
            )}
        </Box>
        {showIntroVideoModal && sellerIntroVideo && (
            <IntroVideoModal
                opened={showIntroVideoModal}
                setOpened={setShowIntroVideoModal}
                videoUrl={sellerIntroVideo}
                profile={profile}
            />
        )}
    </>)
}